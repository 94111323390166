import React, { Component } from "react";
import { NavLink } from 'react-router-dom';


import singleTrip from "../assets/img/jet-img/watersportcarpackage1.webp";
import islandHopping from "../assets/img/jet-img/watersportcarpackage2.webp";
import carRight from "../assets/img/jet-img/series2WaterSportCar.webp";
import jetVideo1 from "../assets/img/jet-img/jetVideo2.mp4";
import jetVideo2 from "../assets/img/jet-img/jetVideo3.mp4";
import promo1 from "../assets/img/new-year-promo.png";

import { useAuthContext } from '../hooks/useAuthContext';

const Packages = () => {

  const { loggedInUser } = useAuthContext();
  
  return (
    <>
      <div id="package" className="container py-5 package-section">

        <div className="row">
          <div className="col-12 text-center">
            <h3>BOOK OUR TOUR</h3>
          </div>
        </div>

        <div className="packageFlex">
          <div className="packageWrap d-flex">

            <div>
              <img src={singleTrip} alt="Water Sport Car In Langkawi"></img>
              <div className="package-container text-center">
                <h5 className="text-white mb-0">Single Tour</h5>
                <p className="text-white mb-0 mb-sm-2">(30 minutes)</p>
                <p className="text-white mb-0" style={{fontSize:"18px", textDecoration:"line-through"}}>RM1200</p>
                <p className="text-white mb-0" style={{fontSize:"24px"}}><strong>RM960</strong></p>
                <button onClick={(e) => {
                  // if (!loggedInUser) {
                  //   window.location.href= "/login";
                  // } else {
                  //   window.location.href= "/booking";
                  // }
                  window.location.href= "/booking";
                }}>
                  BOOK NOW
                </button>

              </div>
            </div>

            <div>
              <img src={islandHopping} alt="Water Sport Car In Langkawi"></img>
              <div className="package-container text-center">
                <h5 className="mb-3">Island Hopping Tour</h5>
                <NavLink to="/booking">
                  <button disabled>Coming Soon</button>
                </NavLink>
              </div>
            </div>

          </div>
        </div>
        <div className="packageFlex">
          <div className="packageWrap d-flex">
            <div>
              <img src={promo1} alt="Water Sport Car In Langkawi"></img>
              <div className="package-container text-center">
                <h5 className="text-white mb-0">New Year Promotion</h5>
                <p className="text-white mb-0 mb-sm-2">Sunset Cruise with Dinner</p>
                <p className="text-white mb-0" style={{fontSize:"18px", textDecoration:"line-through"}}>RM480/pax</p>
                <p className="text-white mb-0" style={{fontSize:"24px"}}><strong>RM420/pax</strong></p>
                <button onClick={(e) => {
                  window.location.href= "/booking";
                }}>
                  BOOK NOW
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );

};

export default Packages;