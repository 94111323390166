// Dependencies
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import SquareLogo from './assets/img/logo.png';
import FooterLogo from './assets/img/footer-logo.png';
import series1 from './assets/img/jet-img/series1WaterSportCar.webp';
import series2 from './assets/img/jet-img/series2WaterSportCar.webp';

// Components
import ContactUsIcon from "./components/contact-us-icon";
import Footer from './components/footer';

// Pages
import Home from "./pages/home";
import Booking from "./pages/booking";

import LoginPage from "./pages/login/login.js";
import RegisterPage from "./pages/register/register.js";
import ProfilePage from "./pages/profile/profile.js";

import RefundPage from "./pages/refund-policy.js";
import PrivacyPage from "./pages/privacy-policy.js";
import TermsAndConditionsPage from "./pages/terms-and-conditions.js";

// Hooks
import { useLogout } from './hooks/useLogout';
import { useAuthContext } from './hooks/useAuthContext';


function App() {

  const { loggedInUser } = useAuthContext();

  const { logout } = useLogout();
  const handleLogout = () => {
    logout();
    window.location.href= "/";
  }

  const [isOpen, setIsOpen] = useState(false);

  const openSidebar = () => {
    setIsOpen(true);
  };

  // Function to close the sidebar
  const closeSidebar = () => {
    setIsOpen(false);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleScrollAndClose = (sectionId) => {
    scrollToSection(sectionId);
    closeSidebar();
  };

  useEffect(() => {
    console.log(loggedInUser);
  }, []);

  return (

    <Router>
      <ContactUsIcon/>

      <section className="header-section">

        <div className="container px-0 desktop-md">
          <div className="row py-3">

            <div className="col-7 px-0">
              <button className="btn" onClick={() => window.location.href = "/"}>
                <p className="text-white mb-0" style={{fontSize:"14px",fontWeight:"700"}}>HOME</p>
              </button>
              <button className="btn" onClick={() => window.location.href = "/#package"}>
                <p className="text-white mb-0" style={{fontSize:"14px",fontWeight:"700"}}>OUR TOUR</p>
              </button>
              <button className="btn" onClick={(e) => {
                // if (!loggedInUser) {
                //   window.location.href= "/login";
                // } else {
                //   window.location.href= "/booking";
                // }
                window.location.href= "/booking";
              }}>
                <p className="text-white mb-0" style={{fontSize:"14px",fontWeight:"700"}}>BOOK NOW</p>
              </button>
            </div>

            <div className="col-5 px-0 text-right">
              {loggedInUser == null ? (
                <>
                  <button className="btn action-button" onClick={(e) => {
                    window.location.href= "/login";
                  }}>
                    <p className="mb-0" style={{fontSize:"14px",fontWeight:"700"}}><i className="fa-solid fa-user mr-2"></i>LOGIN</p>
                  </button>
                </>
              ):(
                <>
                  <button className="btn action-button" onClick={(e) => {
                    window.location.href= "/profile";
                  }}>
                    <p className="mb-0" style={{fontSize:"14px",fontWeight:"700"}}><i className="fa-solid fa-user mr-2"></i>Profile</p>
                  </button>
                </>
              )}
            </div>

          </div>
        </div>

        <div className="container px-0 mobile-md">
          <div className="row py-3">

            <div className="col-6 px-0">
              <button className="btn p-0" onClick={(e) => {
                window.location.href= "/";
              }}>
                <img className="header-logo" src={SquareLogo}></img>
              </button>
            </div>

            <div className={`col-6 px-0 text-right ${isOpen ? 'open' : ''}`}>
              <button className="btn p-0 mx-2" onClick={openSidebar}>
                <p className="header-menu-icon mb-0"><i className="fa-sharp fa-solid fa-bars"></i></p>
              </button>
            </div>

          </div>
        </div>


        <div className={`sidebar ${isOpen ? 'open' : ''}`}>
          <div className='sidebar-top'>
            <div className="logo-section">
              <button className="btn p-0" onClick={(e) => {
                window.location.href= "/";
              }}>
                <img className="header-logo mt-3" src={SquareLogo}></img>
              </button>
            </div>
            <div onClick={closeSidebar} className='closeIcon'>&#x2715;</div>
          </div>

          
          <div className="menu-nav-container">
              <button className="btn" onClick={() => window.location.href = "/"}>
                <p className="header-menu-nav mb-0">HOME</p>
              </button>
              <button className="btn" onClick={(e) => {
                closeSidebar();
                window.location.href = "/#package"
              }}>
                <p className="header-menu-nav mb-0">OUR TOUR</p>
              </button>
              <button className="btn" onClick={(e) => {
                // if (!loggedInUser) {
                //   window.location.href= "/login";
                // } else {
                //   window.location.href= "/booking";
                // }
                window.location.href= "/booking";
              }}>
                <p className="header-menu-nav mb-0">BOOK NOW</p>
              </button>
              {loggedInUser != null ? (
                <>
                  <button className="btn" onClick={(e) => {
                    window.location.href= "/profile";
                  }}>
                    <p className="header-menu-nav mb-0">MY PROFILE</p>
                  </button>
                </>
              ):(
                <></>
              )}
            </div>
            <div className="user-action-section">
              {loggedInUser == null ? (
                <button className="btn p-0" onClick={(e) => {
                  window.location.href = "/login"
                }}>
                  <p className="header-menu-nav mb-0 register-btn">LOGIN</p>
                </button>
              ):(
                <></>
              )}
            </div>
        </div>
        
      </section>

      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/booking" element={<Booking/>}/>
        <Route path="/login" element={<LoginPage/>}/>
        <Route path="/register" element={<RegisterPage/>}/>
        <Route path="/profile" element={<ProfilePage/>}/>
        <Route path="/tnc" element={<TermsAndConditionsPage/>}/>
        <Route path="/refund" element={<RefundPage/>}/>
        <Route path="/privacy" element={<PrivacyPage/>}/>
      </Routes>

      <Footer/>
      
    </Router>
  );
}

export default App;