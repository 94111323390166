import React, { Component } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, Navigation, FreeMode } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';


import SquareLogo from '../assets/img/logo.png';
import bannerimg from '../assets/img/jet-img/watersportcarpackage1.webp';

import watersportcar1 from "../assets/img/jet-img/watersportcarvideo1.mp4";
import watersportcar2 from "../assets/img/jet-img/watersportcarvideo2.mp4";
import watersportcar3 from "../assets/img/jet-img/watersportcarvideo3.mp4";

SwiperCore.use([Autoplay, Pagination, Navigation, FreeMode]);

const HeroBanner = () => {
  
  return (
    <>
      <div className="container-fluid video-section">
        <div className="row">

            <section>
              <div className="videoContent mt-5">
                <img className="hero-logo mx-auto mt-5 desktop-md" src={SquareLogo}></img>
                <h1>The First Luxury Water Sports Car</h1>
                <p>Based in Langkawi, Malaysia</p>
                <p className="my-5">
                  explore now
                  <br/>
                  <i className="fa-regular fa-angles-down fs-2"></i>
                </p>
              </div>
              {/* <video loop autoPlay muted className='w-100'>
                <source src={watersportcar1} type="video/mp4" />
              </video> */}
              <img src={bannerimg} className="banner-img w-100" />
            </section>
            
        </div>
      </div>
    </>
  );

};

export default HeroBanner;