import React, { useEffect, useState } from 'react';
import axios from 'axios';

import '../assets/css/booking.css';

import { useAuthContext } from '../hooks/useAuthContext';
import { useBooking } from '../hooks/useBooking';

import RedJet from '../assets/img/jet-img/red.jpg';
import PinkJet from '../assets/img/jet-img/pink.jpg';
import OrangeJet from '../assets/img/jet-img/orange.jpg';
import NewYearPromo from '../assets/img/new-year-promo-full.png';

const Booking = () => {

  const { loggedInUser } = useAuthContext();
  const { guestBooking, bookingWithAccount } = useBooking();
  
  const [userData, setUserData] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [selectedJet, setSelectedJet] = useState('');
  const [selectedPackage, setSelectedPackage] = useState('');
  const [selectedPax, setSelectedPax] = useState(1);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [bookedSlots, setBookedSlots] = useState([]);
  const [timeOptions, setTimeOptions] = useState([]);
  const [openDropdown, setOpenDropdown] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [promoCode, setPromoCode] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState(960);
  const [promoError, setPromoError] = useState("");
  const [promoDesc, setPromoDesc] = useState("");
  const [promoApplied, setPromoApplied] = useState(false);
  const [createAccount, setCreateAccount] = useState(false);
  const [notice, setNotice] = useState('');

  const [bookingCounter, setBookingCounter] = useState(0);
  const fetchBookingCount = async () => {
    const response = await fetch('https://api.epicjet.com.my/booking/count-booking', {
      method: 'GET',
			headers: {
        'Content-Type': 'application/json'
			}
		});
		const json = await response.json();
		if (response.ok) {
      console.log("New Booking Count", json.totalBookings);
      setBookingCounter(json.totalBookings);
		} 
	};

  const fetchUserData = async () => {
    const response = await fetch('https://api.epicjet.com.my/user/id/'+loggedInUser.user._id, {
      method: 'GET',
			headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${loggedInUser.token}`
			}
		});
		const json = await response.json();
		if (response.ok) {
      console.log(json);
			setUserData(json);
      setIsLoggedIn(true);
		} 
    // else {
    //   window.location.href = "/login";
    // }
	};

  useEffect(() => {
    fetchBookingCount();
    console.log("Booking page:", loggedInUser);
    if (loggedInUser) {
      fetchUserData();
    }
  }, [loggedInUser]);

  const jets = [
    'Red Epic Car Jet (2 Seater)',
    'Pink Epic Car Jet (4 Seater)',
    'Orange Epic Car Jet (4 Seater)'
  ];

  const jetImages = {
    Red: RedJet,
    Pink: PinkJet,
    Orange: OrangeJet
  };

  const locations = ['National Sail Training Center @ Kuah, Langkawi'];

  const packages = [
    { name: 'Single Tour', value: 'single_tour', disabled: false },
    { name: 'New Year Promo', value: 'new_year_promo', disabled: false },
    { name: 'Island Hopping Tour (Coming Soon)', value: 'island_hopping', disabled: true },
  ];

  const timeSlots = [
    '08:30 - 09:00', '09:00 - 09:30', '09:30 - 10:00', '10:00 - 10:30',
    '10:30 - 11:00', '11:00 - 11:30', '11:30 - 12:00', '12:00 - 12:30',
    '12:30 - 13:00', '13:00 - 13:30', '13:30 - 14:00', '14:00 - 14:30',
    '14:30 - 15:00', '15:00 - 15:30', '15:30 - 16:00', '16:00 - 16:30',
    '16:30 - 17:00', '17:00 - 17:30', '17:30 - 18:00', '18:00 - 18:30',
    '18:30 - 19:00'
  ];

  const getJetImage = (jet) => {
    if (jet.includes('Red')) return jetImages.Red;
    if (jet.includes('Pink')) return jetImages.Pink;
    if (jet.includes('Orange')) return jetImages.Orange;
    return null;
  };
  
  const handleDateChange = (e) => {
    const newDate = e.target.value;
    setSelectedDate(newDate);

    const today = new Date().toISOString().split('T')[0];
    // if (newDate === today && allTodaySlotsPassed()) {
    //   // alert('All of today\'s time slots have passed. Please select the next available day.');
    //   setNotice("All of today's time slots have passed. Please select the next available day.");
    //   clearSelections();
    // } else {
    //   setNotice(""); // Clear the notice if the condition doesn't match
    // }
  };

  const clearSelections = () => {
    setSelectedDate('');
    setSelectedTime('');
    setSelectedJet('');
    setSelectedPackage('');
    setSelectedLocation('');
  };

  const handleTimeSelect = (time, isUnavailable) => {
    if (!isUnavailable) {
      setSelectedTime(time);
    }
  };

  const handleJetSelect = (jet, isUnavailable) => {
    if (!isUnavailable) {
      setSelectedJet(jet);
    }
  };

  const handlePackageSelect = (pkg, isUnavailable) => {
    if (!isUnavailable) {
      setSelectedPackage(pkg);
      // if (selectedPackage === "Single Tour") {
      //   setOriginalPrice(960);
      // }
      // if (selectedPackage === "New Year Promo") {
      //   setOriginalPrice(420);
      // }
    }
  };

  const handleSelectPax = (e) => {
    const newPaxValue = e.target.value;
    setSelectedPax(newPaxValue);
  };

  const handleLocationSelect = (location) => {
    setSelectedLocation(location);
  };

  const isFullyBookedTimeslot = (time) => {
    const bookedForTime = bookedSlots.filter(booking => booking.date === selectedDate && booking.time === time);
    return bookedForTime.length === jets.length;
  };

  const isJetUnavailableForTimeslot = (jet) => {
    return bookedSlots.some(booking => booking.date === selectedDate && booking.time === selectedTime && booking.jet === jet);
  };

  const allTodaySlotsPassed = () => {
    const today = new Date().toISOString().split('T')[0];  
    return timeSlots.every(slot => {
      const [slotTime] = slot.split(' - ');
      const slotDateTime = new Date(`${today}T${slotTime}`);
      return slotDateTime < new Date();
    });
  };

  const isPastTimeSlot = (slot) => {
    const [slotTime] = slot.split(' - ');
    const slotDateTime = new Date(`${selectedDate}T${slotTime}`);
    return slotDateTime < new Date();
  };

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0];
    document.getElementById('date').setAttribute('min', today);
  }, []);

  useEffect(() => {
    if (selectedDate) {
      const selectedDay = new Date(selectedDate).getDay();
      let availableSlots = timeSlots.map(slot => {
        const isPast = isPastTimeSlot(slot);
        return {
          time: slot,
          disabled: isPast
        };
      });
      setTimeOptions(availableSlots);
    }
  }, [selectedDate]);
  
  useEffect(() => {
    if (selectedTime) {
      const availableJets = jets.filter(jet => !isJetUnavailableForTimeslot(jet));
      if (availableJets.length === 0) {
        alert("No jets available for this timeslot.");
        setSelectedTime('');
      }
    }
  }, [selectedTime, bookedSlots]);

  const sendTelegramMessage = async (message) => {
    const botToken = '7927542789:AAHz36YIrcY_S-YHovw__wNW2-b8j5eoBug';
    const chatId = '-4511099748';

    try {
      await axios.post(`https://api.telegram.org/bot${botToken}/sendMessage`, {
        chat_id: chatId,
        text: message,
      });
    } catch (error) {
      console.error('Error sending message to Telegram:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedPackage === "New Year Promo") {
      if (!isLoggedIn && (!name || !email || !phone || !selectedPackage || !selectedDate)) {
        alert("Please complete the form to proceed with the booking.");
        return;
      }
    } else {
      if (!isLoggedIn && (!name || !email || !phone || !selectedPackage || !selectedDate || !selectedTime || !selectedJet || !selectedLocation)) {
        alert("Please complete the form to proceed with the booking.");
        return;
      }
    }

    const bookingData = {
      date: selectedDate,
      time: selectedTime,
      jet: selectedJet,
      package: selectedPackage,
      location: selectedLocation,
    };

    fetchBookingCount();

    if (selectedPackage === "New Year Promo") {
      if (isLoggedIn) {
        console.log("Customer Booking: New Year Promo");
        await bookingWithAccount(loggedInUser.token, `EJH2500${bookingCounter+1}`, userData._id, userData.email, userData.phone, selectedDate, "N/A", "N/A", selectedPackage, selectedPax, 'National Sail Training Center @ Kuah, Langkawi', 420*selectedPax, "673d99c1553a7b4eb8bbe298");
      } else {
        console.log("Guest Booking: New Year Promo");
        await guestBooking(`EJH2500${bookingCounter+1}`, name, email, phone, selectedDate, "N/A", "N/A", selectedPackage, selectedPax, 'National Sail Training Center @ Kuah, Langkawi', 420*selectedPax, "673d99c1553a7b4eb8bbe298");
      }
    } else {
      if (isLoggedIn) {
        console.log("Customer Booking: New Year Promo");
        await bookingWithAccount(loggedInUser.token, `EJH2500${bookingCounter+1}`, userData._id, userData.email, userData.phone, selectedDate, selectedTime, selectedJet, selectedPackage, selectedPax, selectedLocation, discountedPrice, "673d99c1553a7b4eb8bbe298");
      } else {
        console.log("Guest Booking: New Year Promo");
        await guestBooking(`EJH2500${bookingCounter+1}`, name, email, phone, selectedDate, selectedTime, selectedJet, selectedPackage, selectedPax, selectedLocation, discountedPrice, "673d99c1553a7b4eb8bbe298");
      }
    }

    
    const newBooking = { 
      name: isLoggedIn && userData.name != "" ? userData.name : name,
      email: isLoggedIn ? userData.email : email,
      phone: isLoggedIn && userData.phone != "" ? userData.phone : phone,
      date: selectedDate, 
      time: selectedTime, 
      jet: selectedJet,
      package: selectedPackage,
      location: selectedLocation
    };
    setBookedSlots([...bookedSlots, newBooking]);

    const message = 
    `New booking:
    Customer Name: ${isLoggedIn && userData.name != "" ? userData.name : name}
    Customer Contact: ${isLoggedIn && userData.phone != "" ? userData.phone : phone}
    Customer Email: ${isLoggedIn ? userData.email : email}
    Date: ${selectedDate}
    Time: ${selectedTime}
    Package: ${selectedPackage}
    Jet Color: ${selectedJet}
    Location: ${selectedLocation}`;

    await sendTelegramMessage(message);

    alert(`Booking confirmed for ${selectedPackage} package, ${selectedTime} on ${selectedDate} with ${selectedJet} at ${selectedLocation}.`);
    setSelectedDate('');
    setSelectedTime('');
    setSelectedJet('');
    setSelectedPackage('');
    setSelectedLocation('');
  };

  const [originalPrice, setOriginalPrice] = useState(960);
  const discountRate = 0.1;

  const handleApplyPromo = () => {
    if (promoCode.trim() === "DCONCEPT") {
      setDiscountedPrice((originalPrice*selectedPax)-(originalPrice*selectedPax*discountRate));
      setPromoDesc("Promo code applied. DCONCEPT 10% Off");
      setPromoError("");
      setPromoCode("");
      setPromoApplied(true);
    } else {
      setDiscountedPrice(960);
      setPromoError("Invalid promo code. Please try again.");
      setPromoDesc("");
      setPromoCode("");
    }
  };

  return (
    <>
      <section className="section-preset extra-mt">
        <div className="container">
          <div className="row">
            <div className="col-12 p-1">
              <h4 className="text-center my-3"><strong>Book an Epic Trip now!</strong></h4>
            </div>
          </div>

          <form className="booking" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12 col-lg-8 p-1">
                <div className="profile-data-box">
                  { !isLoggedIn && (
                    <div className="user-details mt-3 mb-4">
                      <p className="m-0 text-center" style={{ fontSize:'14px' }}>Have an account? <a href="/login">Login here</a></p>
                      <p className="m-0 text-center" style={{ fontSize:'14px' }}>or</p>
                      <p className="m-0 text-center" style={{ fontSize:'14px' }}>Please fill in the details below.</p>
                      <div className="user-details-field">
                        <label>
                          <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} className="form-control" disabled={isLoggedIn} />
                        </label>
                        <label>
                          <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" disabled={isLoggedIn} />
                        </label>
                        <label>
                          <input type="tel" placeholder="Phone number" value={phone} onChange={(e) => setPhone(e.target.value)} className="form-control" disabled={isLoggedIn} />
                        </label>
                      </div>
                    </div>
                    )
                  }
                
                  <div className="select-package">
                    <CustomSelect
                      options={packages}
                      selectedOption={selectedPackage}
                      onSelect={handlePackageSelect}
                      isDisabled={false}
                      isUnavailable={(pkg) => pkg.disabled}
                      placeholder="Select a package"
                      openDropdown={openDropdown}
                      setOpenDropdown={setOpenDropdown}
                      dropdownId="package"
                    />
                  </div>

                  <input
                    type="date"
                    id="date"
                    value={selectedDate}
                    onChange={handleDateChange}
                    min={new Date().toISOString().split('T')[0]}
                    className="form-control"
                    disabled={!selectedPackage}
                  />

                  {notice && <p className="text-danger">{notice}</p>}

                  { selectedPackage === "New Year Promo" ? (
                    <>
                      <img className="w-100 my-2" src={NewYearPromo} />
                      <h5 className="mt-4">New Year Promo (Jan 15 - Feb 15)</h5>
                      <p className="m-0">Sunset Cruise with dinner</p>
                      <p className="">Cruising time: <span className="text-decoration-line-through">4pm</span> 3:30pm - 8pm (extra 30 mins)</p>
                      <h6 className="m-0">Activities Included:</h6>
                      <p className="m-0">Cruise - worth RM180/4-hour (now 4.30hrs)</p>
                      <p className="m-0">Epic Car Jet - worth RM180/10 mins</p>
                      <p className="m-0">Jetski - worth RM80/10 mins</p>
                      <p className="m-0">Banana Boat - worth RM20/ride</p>

                    </>
                  ) : (
                  <>
                    <div className="select-time">
                    <CustomSelect
                      options={timeOptions}
                      selectedOption={selectedTime}
                      onSelect={handleTimeSelect}
                      isDisabled={!selectedDate}
                      isUnavailable={(option) => option.disabled || isFullyBookedTimeslot(option.time)}
                      showFullyBookedLabel={true}
                      placeholder="Select a timeslot"
                      openDropdown={openDropdown}
                      setOpenDropdown={setOpenDropdown}
                      dropdownId="timeslot"
                    />
                    </div>

                    <div className="select-jet">
                      <CustomSelect
                        options={jets}
                        selectedOption={selectedJet}
                        onSelect={handleJetSelect}
                        isDisabled={!selectedDate || !selectedTime || selectedPackage == "New Year Promo"}
                        isUnavailable={isJetUnavailableForTimeslot}
                        showFullyBookedLabel={false}
                        placeholder="Select a jet"
                        openDropdown={openDropdown}
                        setOpenDropdown={setOpenDropdown}
                        dropdownId="jet"
                      />
                    </div>

                    <div className="select-location">
                      <CustomSelect
                        options={locations}
                        selectedOption={selectedLocation}
                        onSelect={handleLocationSelect}
                        isDisabled={false} 
                        isUnavailable={() => false}
                        placeholder="Select a pick up location"
                        openDropdown={openDropdown}
                        setOpenDropdown={setOpenDropdown}
                        dropdownId="location"
                      />
                    </div>
                  </>
                  )}
                </div>
              </div>

              <div className="col-12 col-lg-4 p-1">
                <div className="booking-info">
                  <h5 className="mb-4"><strong>Booking Information</strong></h5>
                  {selectedPackage === "Single Tour" ? (
                    <div>
                      <p className="mb-0"><strong>Package:</strong> {selectedPackage}</p>
                      <p className="mb-0"><strong>Date:</strong> {selectedDate || 'Not selected'}</p>
                      <p className="mb-0"><strong>Time:</strong> {selectedTime || 'Not selected'}</p>
                      <p className="mb-0"><strong>Jet:</strong> {selectedJet || 'Not selected'}</p>
                      {selectedJet && (
                        <div className="jet-image-container">
                          <img
                            src={getJetImage(selectedJet)}
                            alt={selectedJet}
                            className="jet-image w-75 my-4"
                          />
                        </div>
                      )}
                      <p><strong>Location:</strong> {selectedLocation || 'Not selected'}</p>
                      {selectedLocation && (
                        <div>
                          <p className="mb-0" style={{fontSize:"18px"}}>
                            <span style={{marginRight:"5px",textDecoration:"line-through"}}>RM1200</span>
                            <strong>RM{discountedPrice}</strong>
                          </p>
                          {!promoApplied && (
                            <div className="d-flex align-items-center">
                              <input
                                placeholder="Have a promo code?"
                                value={promoCode}
                                onChange={(e) => setPromoCode(e.target.value.toUpperCase())}
                                className="promo-input"
                              />
                              <button
                                className="btn promo-button ml-2"
                                type="button"
                                onClick={handleApplyPromo}
                              >
                                <strong>Apply</strong>
                              </button>
                            </div>
                          )}
                            {promoError && <p className="text-danger">{promoError}</p>}
                            {promoDesc &&  <p className="text-success mt-2">{promoDesc}</p>}
                        </div>
                      )}
                      <button disabled={selectedLocation == ""} className="btn setting-button mt-2" type="submit">
                        <p className="mb-0"><strong>Book Now</strong></p>
                      </button>
                    </div>
                  ) : selectedPackage === "New Year Promo" ? (
                    <div>
                      <p className="mb-0"><strong>Package:</strong> {selectedPackage}</p>
                      <p className="mb-0"><strong>Date:</strong> {selectedDate || 'Not selected'}</p>
                      {selectedDate && (
                        <div>
                          <p className="mb-0"><strong>Price:</strong></p>
                          <p className="mb-0 text-decoration-line-through" style={{fontSize:"16px"}}>
                            RM{parseFloat(480).toFixed(0)}/pax
                          </p>
                          <p className="profit-green mb-0" style={{fontSize:"18px"}}>
                            <strong>RM{parseFloat(420).toFixed(0)}/pax</strong>
                          </p>
                          <p className="mt-3 mb-0"><strong>Select Pax:</strong></p>
                          <input
                            type="number"
                            id="pax"
                            value={selectedPax}
                            onChange={handleSelectPax}
                            className="mt-0 form-control"
                          />
                          <p className="mt-3 mb-0"><strong>Grand Total:</strong></p>
                          <p className="mb-0" style={{fontSize:"18px"}}>
                            RM420 x {selectedPax} = RM{parseFloat(420*selectedPax).toFixed(0)}
                          </p>
                        </div>
                      )}
                      <button disabled={selectedDate == ""} className="btn setting-button mt-3" type="submit">
                        <p className="mb-0"><strong>Book Now</strong></p>
                      </button>
                    </div>
                  ) : (
                    <p>Please select your preferred package</p>
                  )}
                </div>
              </div>
            </div>
          </form>

        </div>
      </section>
    </>
  );
};

const CustomSelect = ({
  options,
  selectedOption,
  onSelect,
  isDisabled,
  placeholder,
  isUnavailable,
  showFullyBookedLabel,
  openDropdown,
  setOpenDropdown,
  dropdownId
}) => {
  const isOpen = openDropdown === dropdownId;

  const handleSelect = (option) => {
    const unavailable = isUnavailable(option);
    onSelect(option.time || option.name || option, unavailable);
    if (!unavailable) {
      setOpenDropdown('');
    }
  };

  return (
    <div className="custom-select-container">
      <div
        className={`custom-select-header ${isDisabled ? 'disabled' : ''}`}
        onClick={() => {
          if (!isDisabled) {
            setOpenDropdown(isOpen ? '' : dropdownId);
          }
        }}
      >
        {selectedOption || placeholder}
        <span className="arrow">{isOpen && !isDisabled ? '▲' : '▼'}</span>
      </div>
      {isOpen && !isDisabled && (
        <ul className="custom-select-dropdown">
          {options.map((option) => {
            const unavailable = isUnavailable(option);
            return (
              <li
                key={option.time || option.name || option}
                className={`custom-select-option ${unavailable ? 'unavailable' : ''}`}
                onClick={() => handleSelect(option)}
              >
                <span style={{ textDecoration: unavailable ? 'line-through' : 'none', marginRight: '5px' }}>
                  {option.time || option.name || option}
                </span>
                <span>
                  {unavailable && showFullyBookedLabel ? '(not available)' : ''}
                </span>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};


export default Booking;