import { useState } from 'react';
import { useAuthContext } from './useAuthContext';

export const useBooking = () => {

    const { dispatch } = useAuthContext();

    const guestBooking = async (bookingID, userName, userEmail, userPhone, date, time, jet, selectedTour, pax, location, price, merchant_id) => {
        const response = await fetch('https://api.epicjet.com.my/booking/guest-booking', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                booking_id: bookingID,
                user_name: userName,
                user_email: userEmail,
                user_phone: userPhone,
                date,
                time,
                jet,
                package: selectedTour,
                pax,
                location,
                price,
                merchant_id
            })
        })
        const json = await response.json();
        if (!response.ok) {
            console.log(json);
            console.log("Failed.");
        }
        if (response.ok) {
            console.log("Success.", json);

            // save the user to local storage
            localStorage.setItem('epicjet_login', JSON.stringify(json));

            // update the auth context
            dispatch({type: 'LOGIN', payload: json});

            window.location.href = "/profile#bookings";
        }
    }

    const bookingWithAccount = async (userToken, bookingID, userID, userEmail, userPhone, date, time, jet, selectedTour, pax, location, price, merchant_id) => {
        const response = await fetch('https://api.epicjet.com.my/booking/create-with-acc', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`
            },
            body: JSON.stringify({
                booking_id: bookingID,
                user_id: userID,
                user_email: userEmail,
                user_phone: userPhone,
                date,
                time,
                jet,
                package: selectedTour,
                pax,
                location,
                price,
                merchant_id
            })
        })
        const json = await response.json();
        if (!response.ok) {
            console.log(json);
            console.log("Failed.");
        }
        if (response.ok) {
            console.log("Success.", json);
            window.location.href = "/profile#bookings";
        }
    }

    return { guestBooking, bookingWithAccount };
}